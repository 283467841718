<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <!-- Table -->
            <form class="needs-validation" @submit.prevent="Form">
              <div class="row justify-content-md-center mt-4">
                <div class="col-md-12">
                  <div class="row align-items-center">
                    <div class="col-sm-12 col-md-12 col-12">
                      <!-- <div >
                        <b-alert
                        v-for="(data, index) in rowsData" :key="index"
                          variant="secondary"
                          show
                          class="alert-border alert-border-secondary"
                        >
                          <i
                            class="uil uil-pen font-size-16 text-secondary me-2"
                          ></i>
                          {{ data.description }}
                        </b-alert>
                        <br />
                      </div> -->
                      <simplebar data-simplebar>
                        <a
                          class="text-reset notification-item"
                          v-for="(data, index) in rowsData"
                          :key="index"
                        >
                          <div
                            class="media"
                            :class="{ 'media bg-soft-danger': data.read === 0 }"
                            @click="readNoti(data)"
                          >
                            <div class="avatar-xs me-3">
                              <span
                                class="avatar-title bg-primary rounded-circle font-size-16"
                              >
                                <i class="uil-truck"></i>
                              </span>
                            </div>
                            <div class="media-body">
                              <h6 class="mt-0 mb-1">
                                {{
                                  data.senderName != null
                                    ? data.senderName
                                    : "DMS"
                                }}
                                <small class="text-muted float-end"
                                  ><i class="mdi mdi-clock-outline"></i>
                                  {{ data.createdAt }}</small
                                >
                              </h6>
                              <div class="font-size-12 text-muted">
                                <p class="mb-1">
                                  {{ data.description }}
                                </p>
                                <!-- <p class="mb-0">
                                  <i class="mdi mdi-clock-outline"></i>
                                  {{ data.createdAt }}
                                </p> -->
                              </div>
                            </div>
                          </div>
                        </a>
                      </simplebar>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import useNetw from "@useNetw";
import simplebar from "simplebar-vue";
import {
  required,

  // url,
  // alphaNum,
} from "vuelidate/lib/validators";
// import branchComponent from "@/components/branchComponent.vue";

export default {
  page: {
    title: appConfig.impSale,
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: { Layout, PageHeader, simplebar },

  data() {
    return {
      loading: undefined,
      error: "กรุณาตรวจสอบข้อมูลอีกครั้ง.",
      title: appConfig.notification,
      items: [
        {
          text: "นำเข้าข้อมูล",
          active: true,
        },
        {
          text: appConfig.notificationall,
          active: true,
        },
      ],
      // partId: this.$route.params.partId,
      model: [],
      totalPage: "",
      branchId: [],
      selectMode: "multi",
      selected: [],
      isLoading: "",
      id: "",
      page: "",
      to: "",
      total: "",
      from: "",
      tooltipform: { files: null },

      totalRecord: "",
      rowData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      salaryLabel: "",
      rowsData: [],
    };
  },
  validations: {
    tooltipform: {
      files: {
        required,
      },
    },
  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.rowParts.length;
    },
  },
  mounted() {
    const user = JSON.parse(localStorage.getItem(appConfig.storageUserKey));
    const arrayBranch = [];
    user.userAccessBranch.forEach((item) => {
      arrayBranch.push(item.branchId);
    });

    this.localDataBranchIdUser = arrayBranch;
    this.branchId = user.branchId;
    this.localData = user.userAccessBranch;
    this.getData();
    // Set the initial number of items
  },
  created() {
    // this.getLocalData();
  },
  methods: {
    getData() {
      this.loading = true;

      useNetw
        .get(`api/notification`, {
          params: {
            page: 1,
            perPage: 100,
          },
        })
        .then((response) => {
          this.rowsData = response.data.data;
          // if (response.data.data.isSuccess === 0) {
          //   Swal.fire(
          //     appConfig.swal.title.error,
          //     JSON.stringify(response.data.data.errMsg),
          //     appConfig.swal.type.error
          //   );
          // }
          console.log(response);
          //   Swal.fire(
          //     appConfig.swal.title.postSuccess,
          //     JSON.stringify(response.data.message),
          //     appConfig.swal.type.success
          //   );
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(err.response.data.message),
            appConfig.swal.type.error
          );
          // this.loading = false;
        })
        .then(() => {
          this.loading = false;
        });
    },
    readNoti(data) {
      if (data.read != 1) {
        useNetw
          .put("api/notification/read", {
            notiId: data.notiId,
          })
          .then((response) => {
            // console.log(response.data.num)
            localStorage.setItem("notify", response.data.num);
            this.callNoti();
            window.location.href = `/${data.frontendUrl}${data.extraParameter}`;
          })
          .catch((err) => {
            Swal.fire(
              appConfig.swal.title.error,
              JSON.stringify(
                err.response.data.message +
                  "<br>" +
                  err.response.data.validationMessage
              ),
              appConfig.swal.type.error
            );
          });
      } else {
        // window.location({name: "dealer-import-sales-show",params: { notiId: data.extraParameter }})
        window.location.href = `/${data.frontendUrl}${data.extraParameter}`;
      }
    },
    callNoti() {
      useNetw
        .get("api/notification/lastest")
        .then((response) => {
          this.notification = response.data.notification;
          localStorage.setItem(
            "dataNotification",
            JSON.stringify(this.notification)
          );
          // console.log(response)
        })
        .catch((err) => {
          Swal.fire(
            appConfig.swal.title.error,
            JSON.stringify(
              err.response.data.message +
                "<br>" +
                err.response.data.validationMessage
            ),
            appConfig.swal.type.error
          );
        })
        .then(() => {
          // this.$refs.modalService.hide();
          // this.overlayFlag = false; //skeleton true
        });
    },
    alert(rowData) {
      const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: "btn btn-success",
          cancelButton: "btn btn-danger ms-2",
        },
        buttonsStyling: false,
      });

      swalWithBootstrapButtons
        .fire({
          title: "ต้องการลบหรือหรือไม่ ?",
          icon: "warning",
          confirmButtonText: "ลบ",
          cancelButtonText: "ยกเลิก",
          showCancelButton: true,
        })
        .then((result) => {
          if (result.value) {
            this.deleteParts(rowData);
          }
        });
    },
  },
  middleware: "authentication",
};
</script>
